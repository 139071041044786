import { HomePage } from '../../components/pages/home';
import Layout from '../../components/shared/Layout';
import { usePageVistTracker } from '../../hooks/usePageVistTracker';
import { DeviceTypes } from '../../utils/getDeviceType';

const HomePageWrapper = (props: {
  deviceType?: DeviceTypes;
}): JSX.Element => {
  usePageVistTracker();

  return (
    <Layout
      description="Use Brain.fm's functional music to help you focus relax and sleep better, using cutting edge neuroscience to get results."
      pageTitle="Music to Focus Better - Brain.fm"
    >
      <HomePage
        animatedMentalStateVariant="B"
        deviceType={props.deviceType}
      />
    </Layout>
  );
};

export default HomePageWrapper;
