import { usePageVistTracker } from '../hooks/usePageVistTracker';
import { DeviceTypes } from '../utils/getDeviceType';
import HomePage from './home';

declare global {
  const AppleID: any;
  const FB: any;
  const google: typeof import('google-one-tap');
}

type Props = {
  deviceType?: DeviceTypes;
};

const Home = (props: Props): JSX.Element => {
  usePageVistTracker();

  return <HomePage deviceType={props.deviceType} />;
};

export default Home;
